h1, h2, h3, h4, h5, h6 {
  color: #0f4880!important;
}

.footer a, .footer *, .footer-text{
  color: white!important;
}

.btn-primary, .badge, .bg-brand, .footer, .navbar {
  background-color: #0f4880!important;
}

.subtitle, .bi, .theme-text{
  color: #0f4880!important;
}

.content {
  min-height: 300px;
}
